import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

export default function ConfirmationDialog({ open, setOpen, handleSubmit }) {
  const [isAnimating, setIsAnimating] = React.useState(false);
  const [formData, setFormData] = React.useState({
    payroll_name: "",
    months: "",
    period: "",
  });

  const handleTextChange = (field, value) => {
    setFormData({
      ...formData,
      [field]: value,
    });
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = () => {
    setOpen(false);
    handleSubmit(formData);
  };

  const handleDialogClose = (event, reason) => {
    if (reason === "backdropClick") {
      // Trigger animation on backdrop click
      setIsAnimating(true);

      // Wait for the animation to complete before closing
      setTimeout(() => {
        setIsAnimating(false);

        //handleClose();
      }, 300); // Duration of the animation
      return;
    }

    // handleClose();
  };

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleDialogClose}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
        PaperProps={{
          sx: {
            width: "500px",
            height: "400px",
            transform: isAnimating ? "scale(0.9)" : "scale(1)",
            transition: "transform 0.3s ease",
          },
        }}
      >
        <DialogTitle>Create Payroll</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enter the name of the payroll.
          </DialogContentText>
          <TextField
            autoFocus
            required
            margin="dense"
            id="payroll_name"
            name="payroll_name"
            label="Payroll Title"
            type="text"
            fullWidth
            variant="standard"
            onChange={(e) => handleTextChange("payroll_name", e.target.value)}
          />
          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel id="months">Months</InputLabel>
            <Select
              labelId="months"
              id="months"
              name="months"
              label="Months"
              required
              fullWidth
              onChange={(e) => handleTextChange("months", e.target.value)}
            >
              <MenuItem value={1}>January</MenuItem>
              <MenuItem value={2}>February</MenuItem>
              <MenuItem value={3}>March</MenuItem>
              <MenuItem value={4}>April</MenuItem>
              <MenuItem value={5}>May</MenuItem>
              <MenuItem value={6}>June</MenuItem>
              <MenuItem value={7}>July</MenuItem>
              <MenuItem value={8}>August</MenuItem>
              <MenuItem value={9}>September</MenuItem>
              <MenuItem value={10}>October</MenuItem>
              <MenuItem value={11}>November</MenuItem>
              <MenuItem value={12}>December</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel id="period">Period</InputLabel>
            <Select
              labelId="period"
              id="period"
              name="period"
              label="Period"
              required
              fullWidth
              onChange={(e) => handleTextChange("period", e.target.value)}
            >
              <MenuItem value={1}>1-15th</MenuItem>
              <MenuItem value={2}>16th-End</MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <a href="/manage-payroll">
            <Button type="button" variant="contained" color="secondary">
              Cancel
            </Button>
          </a>
          <Button
            type="button"
            variant="contained"
            color="primary"
            onClick={handleSave}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
