import React from "react";
import { Page, Text, View, Document, StyleSheet } from "@react-pdf/renderer";
import dayjs from "dayjs";

// Create styles
const styles = StyleSheet.create({
  page: {
    backgroundColor: "white",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  row: {
    flexDirection: "row",
    marginBottom: 4,
  },
  label: {
    width: "170px",
  },
  value: {
    flex: 1, // Ensures text wraps within the available space
    wordWrap: "break-word", // Breaks long words
  },
  signatureContainer: {
    flexDirection: "row",
    marginTop: 100,
  },
  signature: {
    borderTop: "2px solid black",
    width: "150px",
    textAlign: "center",
    marginHorizontal: 50,
  },
});

const createLeaveData = (data) => {
  const leaveData = (
    <View style={styles.section}>
      <View style={styles.row}>
        <Text style={styles.label}>Name: </Text>
        <Text style={styles.value}>{data.name}</Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.label}>Type of Leave: </Text>
        <Text style={styles.value}>{data.type_of_leave}</Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.label}>Leave Reason: </Text>
        <Text style={styles.value}>{data.leave_reason}</Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.label}>Start Date: </Text>
        <Text style={styles.value}>
          {dayjs(data.start_date).format("MMMM D, YYYY")}
        </Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.label}>End Date: </Text>
        <Text style={styles.value}>
          {dayjs(data.end_date).format("MMMM D, YYYY")}
        </Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.label}>Duration: </Text>
        <Text style={styles.value}>
          {dayjs(data.end_date).diff(dayjs(data.start_date), "days")} days
        </Text>
      </View>
      {/* Signature Area */}
      <View style={styles.signatureContainer}>
        <View style={styles.signature}>
          <Text>Signature</Text>
        </View>
        <View style={styles.signature}>
          <Text>Signature</Text>
        </View>
      </View>
    </View>
  );
  return leaveData;
};

// Create Document Component
const CreateDocument = ({ size, orientation, data }) => (
  <Document>
    <Page size={size} orientation={orientation}>
      {createLeaveData(data)}
    </Page>
  </Document>
);

export default CreateDocument;
