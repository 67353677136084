import React, { useEffect, useState } from "react";
import {
  useGetLeavesQuery,
  useUpdateLeavesMutation,
} from "../../../../app/api/users/usersApiSlice";
import {
  Box,
  Grid,
  Card,
  CardContent,
  Typography,
  IconButton,
} from "@mui/material";
import dayjs from "dayjs";
import VerifiedOutlinedIcon from "@mui/icons-material/VerifiedOutlined";
import BlockOutlinedIcon from "@mui/icons-material/BlockOutlined";
import LocalPrintshopOutlinedIcon from "@mui/icons-material/LocalPrintshopOutlined";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AlertDialog from "../../../../components/modal/AlertDialog";
import CreateDocument from "../../../../components/PDF-report/CreateDocument.js";
import ReactPDF from "@react-pdf/renderer";

function ManageLeaves() {
  const [content, setContent] = React.useState("");
  const [page, setPage] = React.useState(1);
  const [count, setCount] = React.useState(0);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogId, setDialogId] = useState("");
  const [leaveStatus, setLeaveStatus] = useState("");

  const [dialogMessage, setDialogMessage] = useState("");

  const [updateLeaves] = useUpdateLeavesMutation();

  const { data, error, isLoading } = useGetLeavesQuery(
    {
      page: page,
      limit: 12,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const handleChange = (event, value) => {
    setPage(value);
  };

  const handleYesDelete = async () => {
    try {
      const res = await updateLeaves({
        id: dialogId,
        status: leaveStatus,
      });
      if (res) {
        toast.success(res.data.message);
      }
    } catch (err) {
      console.error(err);
    }
    setDialogOpen(false);
  };

  const handleApprove = async (id) => {
    try {
      setDialogOpen(true);
      setDialogId(id);
      setLeaveStatus(1);
      setDialogMessage("Are you sure you want to approve this leave?");
    } catch (err) {
      console.error(err);
    }
  };

  const handleReject = async (id) => {
    try {
      setDialogOpen(true);
      setDialogId(id);
      setLeaveStatus(2);
      setDialogMessage("Are you sure you want to reject this leave?");
    } catch (err) {
      console.error(err);
    }
  };

  const handlePrint = async (id) => {
    try {
      const leaveData = data.data.find((leave) => leave.id === id);
      // Generate the PDF Blob
      const blob = await ReactPDF.pdf(
        <CreateDocument size={"A4"} orientation={"portrait"} data={leaveData} />
      ).toBlob();

      // Open a new window
      const newWindow = window.open("", "_blank");
      if (!newWindow) {
        console.error("Failed to open a new window");
        return;
      }

      // Set up the new window's content
      const pdfUrl = URL.createObjectURL(blob);
      newWindow.document.title = "Leave Request";
      newWindow.document.body.style.margin = "0";
      newWindow.document.body.innerHTML = `<iframe src="${pdfUrl}" style="width: 100%; height: 100%; border: none;"></iframe>`;
    } catch (err) {
      console.error("Error generating PDF:", err);
    }
  };

  useEffect(() => {
    if (data) {
      let contentType = [];
      data.data.map((leave) => {
        contentType.push(
          <Grid item xs={12} sm={6} md={4} key={leave.id}>
            <Card
              sx={{
                height: "100%",
                transition: "transform 0.3s ease",
                border: `1px solid ${
                  leave.status === 1
                    ? "#009900"
                    : leave.status === 2
                    ? "#ff0000"
                    : "#5366d0"
                }`,
                "&:hover": {
                  transform: "scale(1.05)",
                  boxShadow: 3,
                },
              }}
            >
              <CardContent>
                <Typography
                  sx={{
                    fontWeight: 700,
                    fontSize: "24px",
                    textAlign: "center",
                    backgroundColor: "#5366d0",
                    color: "#fff",
                    mb: 2,
                  }}
                  component="div"
                >
                  {leave.name}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    fontWeight: 700,
                    textAlign: "left",
                    fontSize: "16px",
                  }}
                  color="text.secondary"
                >
                  Type of Leave: {leave.type_of_leave}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    fontWeight: 700,
                    textAlign: "left",
                    fontSize: "16px",
                  }}
                  color="text.secondary"
                >
                  Leave Reason: {leave.leave_reason}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    fontWeight: 700,
                    textAlign: "left",
                    fontSize: "15px",
                    mt: 2,
                  }}
                  color="text.secondary"
                >
                  Start Date: {dayjs(leave.start_date).format("DD/MM/YYYY")}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    fontWeight: 700,
                    textAlign: "left",
                    fontSize: "15px",
                  }}
                  color="text.secondary"
                >
                  End Date: {dayjs(leave.end_date).format("DD/MM/YYYY")}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    fontWeight: 700,
                    textAlign: "left",
                    fontSize: "15px",
                  }}
                  color="text.secondary"
                >
                  Duration of leave:{" "}
                  {dayjs(leave.end_date).diff(dayjs(leave.start_date), "days")}{" "}
                  days
                </Typography>
              </CardContent>
              <CardContent sx={{ textAlign: "center" }}>
                <IconButton
                  aria-label="verify"
                  sx={{ float: "center", color: "#3141c7" }}
                  onClick={() => handlePrint(leave.id)}
                >
                  <LocalPrintshopOutlinedIcon />
                </IconButton>
                <IconButton
                  aria-label="verify"
                  sx={{
                    float: "center",
                    color: "#FF0000",
                    display:
                      leave.status === 1 || leave.status === 2 ? "none" : "",
                  }}
                  onClick={() => handleReject(leave.id)}
                >
                  <BlockOutlinedIcon />
                </IconButton>
                <IconButton
                  aria-label="verify"
                  sx={{
                    float: "center",
                    color: "#4CAF50",
                    display:
                      leave.status === 1 || leave.status === 2 ? "none" : "",
                  }}
                  onClick={() => handleApprove(leave.id)}
                >
                  <VerifiedOutlinedIcon />
                </IconButton>
              </CardContent>
            </Card>
          </Grid>
        );
      });
      setContent(contentType);
      setCount(Math.ceil(data.totalCount[0]?.count / 12));
    }
  }, [data]);
  let createLeave = (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        {content}
      </Grid>
      <Stack spacing={2} sx={{ float: "right" }}>
        <Pagination count={count} page={page} onChange={handleChange} />
      </Stack>
      <ToastContainer />
      <AlertDialog
        dialogOpen={dialogOpen}
        setDialogOpen={setDialogOpen}
        title={"Update Leave"}
        content={dialogMessage}
        dialogId={dialogId}
        action={handleYesDelete}
      />
    </Box>
  );
  return createLeave;
}

export default ManageLeaves;
