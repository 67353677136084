import React, { useEffect, useState, useCallback } from "react";
import {
  Checkbox,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TableFooter,
  Autocomplete,
  TextField,
  Box,
  Typography,
  Skeleton,
  Grid,
  Stack,
  Switch,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import PrintIcon from "@mui/icons-material/Print";
import "./DIS.css";
import { styled } from "@mui/material/styles";
import MedicalInformationOutlinedIcon from "@mui/icons-material/MedicalInformationOutlined";

const debounce = (func, delay) => {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, delay);
  };
};

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: "#1890ff",
        ...theme.applyStyles("dark", {
          backgroundColor: "#177ddc",
        }),
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: "rgba(0,0,0,.25)",
    boxSizing: "border-box",
    ...theme.applyStyles("dark", {
      backgroundColor: "rgba(255,255,255,.35)",
    }),
  },
}));

function DISTable({
  data,
  headers,
  options,
  actions,
  customButton,
  customActions,
  others,
}) {
  const [page, setPage] = useState(0);
  const [totalPageNumber, setTotalPageNumber] = useState(
    parseInt(options.totalPageNumber)
  );
  const [rowsPerPage, setRowsPerPage] = useState(options.numberPerPage || 5);
  const [users, setUsers] = useState([]);
  const [positionFilter, setPositionFilter] = useState([]);
  const [positionSelected, setPositionSelected] = useState("");
  const [areaAssignFilter, setAreaAssignFilter] = useState([]);
  const [areaAssignSelected, setAreaAssignSelected] = useState("");
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  console.log("data", data);

  useEffect(() => {
    if (data) {
      const formattedUsers = data;
      setUsers(formattedUsers);
      setTotalPageNumber(options.totalPageNumber);
      options.page(page);
      options.setRowsPerPage(rowsPerPage);

      setTimeout(() => {
        setIsLoading(false);
      }, 500);
    }
  }, [data, options, page, rowsPerPage]);

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      setSelectedUsers(users.map((user) => user.id));
    } else {
      setSelectedUsers([]);
    }
  };

  const onSearchInputChange = (event) => {
    const value = event.target.value;
    setSearchValue(value);
    setIsLoading(true); // Start loading
    setUsers([]); // Clear the current users to prevent displaying old data
    handleSearchChange(value);
    setPage(0); // Reset the page to 0 when searching
  };

  // Update the debounce function to include setIsLoading(false) after the search is complete
  const handleSearchChange = useCallback(
    debounce((value) => {
      options.setSearchValue(value); // Perform your search logic here
      // Simulate an API call delay to set loading to false
      setTimeout(() => {
        setIsLoading(false); // Stop loading after search completes
      }, 300); // Adjust the delay as needed
    }, 1000), // Delay for the search input
    []
  );

  const handleSelectRow = (userId) => {
    const isSelected = selectedUsers.includes(userId);
    setSelectedUsers((prev) =>
      isSelected ? prev.filter((id) => id !== userId) : [...prev, userId]
    );
  };

  const handleSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  //console.log("filteredUsers", filteredUsers);

  const sortedUsers = [...users].sort((a, b) => {
    //sort by lastname then firstname
    if (orderBy === "fullname") {
      const nameA = a.last_name + a.first_name;
      const nameB = b.last_name + b.first_name;
      return order === "asc"
        ? nameA.localeCompare(nameB)
        : nameB.localeCompare(nameA);
    } else {
      return order === "asc"
        ? a[orderBy] - b[orderBy]
        : b[orderBy] - a[orderBy];
    }
  });
  const generateHeaderFilters = (
    <Box sx={{ display: "flex", padding: 2 }}>
      <Stack
        direction={{
          xs: "column",
          sm: "column",
          md: "column",
          lg: "row",
        }}
        sx={{ width: "100%" }}
        spacing={2}
      >
        <TextField
          label="Search"
          value={searchValue}
          onChange={onSearchInputChange}
          fullWidth
          variant="standard"
          type="search" // Set input type to "search"
          sx={{
            "& .MuiInputBase-root": {
              marginTop: 0.5,
              width: {
                xs: { maxWidth: "250px", width: "100%" },
                sm: { minWidth: "200px" },
              }, // Full width on small screens and 200px on larger
            },
            "& .MuiFormLabel-root": {
              top: "-15px",
              fontSize: "12px",
            },
          }}
        />
        {others ? others : null}
        {customButton ? customButton : null}
      </Stack>
    </Box>
  );

  const generateHeader = (
    <TableRow>
      {options.selectable && (
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={
              selectedUsers.length > 0 && selectedUsers.length < users.length
            }
            checked={users.length > 0 && selectedUsers.length === users.length}
            onChange={handleSelectAll}
          />
        </TableCell>
      )}
      {headers.map((header) => (
        <TableCell
          key={header.id}
          //onClick={() => (header.sortable ? handleSort(header.id) : null)}
          style={{
            cursor: header.sortable ? "pointer" : "default",
            fontWeight: "bold",
            color: "white",
            background: "rgba(237, 108, 2, 1)", // added alpha value of 1 for full opacity
          }}
        >
          {header.label}
          {header.sortable && orderBy === header.id ? (
            order === "asc" ? (
              <ArrowUpwardIcon />
            ) : (
              <ArrowDownwardIcon />
            )
          ) : null}
        </TableCell>
      ))}
    </TableRow>
  );

  const generateTableTitle = (
    <Box
      sx={{
        display: "flex",
        padding: 2,
        textAlign: "center",
        justifyContent: "center",
      }}
    >
      <Typography variant="h6" fontWeight={700}>
        {options.tableTitle}
      </Typography>
    </Box>
  );

  const generateRows = sortedUsers
    //.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    .map((user) => (
      <TableRow
        key={user.id}
        onMouseOver={(e) => (e.currentTarget.style.backgroundColor = "#f5f5f5")}
        onMouseOut={(e) => (e.currentTarget.style.backgroundColor = "white")}
      >
        {options.selectable && (
          <TableCell padding="checkbox">
            <Checkbox
              checked={selectedUsers.includes(user.id)}
              onChange={() => handleSelectRow(user.id)}
            />
          </TableCell>
        )}
        {headers.map((header) => (
          <TableCell key={header.id}>
            {header.id === "actions" ? (
              actions ? (
                <Box sx={{ display: "flex" }}>
                  {actions.handleEdit ? (
                    <IconButton
                      onClick={() => actions.handleEdit(user.id)}
                      color="primary"
                    >
                      <EditIcon />
                    </IconButton>
                  ) : null}
                  {actions.handlePrint ? (
                    <IconButton
                      onClick={() => actions.handlePrint(user.id)}
                      color="primary"
                    >
                      <PrintIcon />
                    </IconButton>
                  ) : null}
                  {actions.handleView ? (
                    <IconButton
                      onClick={() => actions.handleView(user.id)}
                      color="primary"
                      title="view"
                    >
                      <VisibilityIcon />
                    </IconButton>
                  ) : null}
                  {actions.handleDelete ? (
                    <IconButton
                      onClick={() => actions.handleDelete(user.id)}
                      color="secondary"
                    >
                      <DeleteIcon />
                    </IconButton>
                  ) : null}
                  {actions.handleSwitchOnOff ? (
                    <Stack
                      direction="row"
                      spacing={1}
                      sx={{ alignItems: "center" }}
                    >
                      <AntSwitch
                        checked={user.status === "Active" ? true : false}
                        inputProps={{ "aria-label": "ant design" }}
                        onClick={() =>
                          actions.handleSwitchOnOff(user.id, user.status)
                        }
                      />
                    </Stack>
                  ) : null}
                  {user.sick_leave ||
                  user.vacation_leave ||
                  user.maternity_leave ||
                  user.special_leave ? (
                    <IconButton
                      onClick={() => actions.handleMedicalRecord(user.id)}
                      color="secondary"
                      title="Medical Information"
                    >
                      <MedicalInformationOutlinedIcon
                        sx={{
                          fill:
                            user.leave_status === 1
                              ? "rgb(14 198 48);"
                              : user.leave_status === 2
                              ? "rgb(214 4 4);"
                              : "rgb(25 118 210);",
                        }}
                      />
                    </IconButton>
                  ) : null}
                </Box>
              ) : (
                ""
              )
            ) : (
              user[header.id]
            )}
          </TableCell>
        ))}
      </TableRow>
    ));

  let rowSkeleton = (
    <>
      {Array.from({ length: 10 }).map((_, i) => (
        <TableRow key={i}>
          {headers.map((header) => (
            <TableCell key={header.id}>
              <Skeleton variant="text" animation="wave" />
            </TableCell>
          ))}
        </TableRow>
      ))}
    </>
  );

  return (
    <TableContainer
      component={Paper}
      sx={{ height: "100%", minHeight: "710px" }}
    >
      {options.tableTitle ? generateTableTitle : ""}
      {generateHeaderFilters}

      <Table aria-label="selectable table" className="DISTable">
        <TableHead>{generateHeader}</TableHead>
        <TableBody>{isLoading ? rowSkeleton : generateRows}</TableBody>
        <TableFooter>
          <TableRow>
            <TableCell colSpan={headers.length + 1}>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={totalPageNumber}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={(event, newPage) => {
                  setIsLoading(true);
                  setPage(newPage);
                }}
                onRowsPerPageChange={(event) => {
                  setIsLoading(true);
                  setRowsPerPage(parseInt(event.target.value, 10));
                }}
              />
            </TableCell>
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}

export default DISTable;
