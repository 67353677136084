import React, { useEffect, useState } from "react";
import {
  Card,
  Grid,
  Paper,
  Stack,
  TextField,
  TableContainer,
  TableCell,
  Table,
  TableHead,
  Autocomplete,
  Typography,
  Button,
  TableRow,
  TableBody,
  Box,
  FormControl,
  FormControlLabel,
  Checkbox,
  FormGroup,
  FormLabel,
  IconButton,
} from "@mui/material";

import { useGetEmployeeWPayrollQuery } from "../../../../app/api/users/usersApiSlice";
import { useGetAllAssignmentQuery } from "../../../../app/api/users/userAssignment";
import {
  useGetPayrollQuery,
  useCreateSignlePayrollMutation,
  useCreatePayrollMutation,
  useGetCheckExistingPayrollQuery,
} from "../../../../app/api/payroll/generatePayroll";
import useAuth from "../../../../hooks/useAuth";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { LoadingButton } from "@mui/lab";
import socketService from "../../../../app/api/ws/initializeWebSocketService";
import InfoIcon from "@mui/icons-material/Info";
import "./createPayroll.css";
import CircularProgress from "@mui/material/CircularProgress";
import BackwardIcon from "@mui/icons-material/ArrowBackIos";
import ConfirmationDialog from "../../../../components/dialog/Confirmation";

const monthData = [
  { value: 1, label: "January" },
  { value: 2, label: "February" },
  { value: 3, label: "March" },
  { value: 4, label: "April" },
  { value: 5, label: "May" },
  { value: 6, label: "June" },
  { value: 7, label: "July" },
  { value: 8, label: "August" },
  { value: 9, label: "September" },
  { value: 10, label: "October" },
  { value: 11, label: "November" },
  { value: 12, label: "December" },
];

function decimalFormat(num) {
  return num.toLocaleString("en-US", {
    style: "decimal",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
}

function CreatePayrollN() {
  const [isBackOpen, setIsBackOpen] = useState(false);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [employeeOptions, setEmployeeOption] = React.useState([]);
  const [assignData, setAssignData] = React.useState([]);
  const [assignOptions, setAssignOptions] = React.useState([]);
  const [employeeName, setEmployeeName] = React.useState(null);
  const [createPayroll] = useCreateSignlePayrollMutation();
  const [employeeTable, setEmployeeTable] = useState([]);
  const [allowAdjustment, setAllowAdjustment] = useState({
    updateBasicRate: true,
    enableCashAdvance: true,
    reliever: true,
  });
  const [totalTable, setTotalTable] = useState([]);
  const [error, setError] = React.useState(false);
  const [activeRow, setActiveRow] = useState(null);
  const {
    branch_no,
    branch_name,
    id,
    assign_id,
    area_assign,
    roles,
    isSuperAdmin,
    local_user_id,
  } = useAuth();
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedValue, setSelectedValue] = useState(null); // This is the option for "Twenty"
  const [selectAssign, setSelectAssign] = useState(null);
  const [checkEncoded_by, setCheckEncoded_by] = useState(0);

  const [createPayrollHeader] = useCreatePayrollMutation();

  const [checkAdjustment, setCheckAdjustment] = useState({
    updateBasicRate: false,
    enableCashAdvance: false,
    reliever: false,
  });
  const navigate = useNavigate();

  const [payrollNumber, setPayrollNumber] = useState(0);
  const location = useLocation();

  const getQueryParams = (search) => {
    const params = new URLSearchParams(search);
    return {
      month: params.get("month"),
      year: params.get("year"),
      period: params.get("period"),
      branch_id: params.get("branch_id"),
      payroll_id: params.get("payroll_id"),
    };
  };

  const queryParams = getQueryParams(location.search);

  const [filterDTR, setFilterDTR] = useState({
    month: parseInt(
      queryParams.month ? queryParams.month : new Date().getMonth() + 1
    ),
    year: queryParams.year ? queryParams.year : new Date().getFullYear(),
    period: queryParams.period
      ? queryParams.period
      : new Date().getDate() < 16
      ? 1
      : 2,
    branch_id: queryParams.branch_id ? queryParams.branch_id : branch_no,
    user_id: id,
    payroll_id: queryParams.payroll_id,
    payroll_name: "",
  });
  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === "F5") {
        navigate(`${location.pathname}${location.search}`);
      }
    };

    const handleBeforeUnload = (event) => {
      if (hasUnsavedChanges) {
        event.preventDefault();
        event.returnValue = "";
      }
    };

    window.addEventListener("keydown", handleKeyPress);
    window.addEventListener("beforeunload", handleBeforeUnload);

    // Cleanup event listeners
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [navigate, location.pathname, hasUnsavedChanges, filterDTR]);

  const {
    data: checkExistingPayrollData,
    isLoading: checkExistingPayrollLoading,
  } = useGetCheckExistingPayrollQuery(
    {
      payroll_id: filterDTR.payroll_id,
    },
    {
      refetchOnMountOrArgChange: true,
      skip: !filterDTR.payroll_id,
    }
  );

  useEffect(() => {
    if (
      !filterDTR.payroll_id ||
      filterDTR.payroll_id === "undefined" ||
      filterDTR.payroll_id === "null"
    ) {
      setIsBackOpen(true);
      return;
    } else {
      filterDTR.payroll_name = checkExistingPayrollData?.name;
      filterDTR.month = checkExistingPayrollData?.month;
      filterDTR.year = checkExistingPayrollData?.year;
      filterDTR.period = checkExistingPayrollData?.period;
    }
  }, [
    filterDTR.payroll_id,
    filterDTR.payroll_name,
    filterDTR.month,
    filterDTR.year,
    filterDTR.period,
    checkExistingPayrollData,
  ]);

  const [employeeInfo, setEmployeeInfo] = React.useState({
    employee_id: "",
    assign_area: "",
    last_name: "",
    first_name: "",
    middle_initial: "",
    position: "",
    salary_rate: "",
    basic_pay: "",
    new_salary_rate: "",
    new_basic_pay: "",
    special_holiday: "",
    regular_holiday: "",
    s_absent: "",
    sss: "",
    phlt: "",
    pag_ibig: "",
    allowance_adj: "",
    cash: "",
    prev_balance: "",
    payment: "",
    remaining_balance: "",
    ot: "",
    ot_amount: "",
    undertime_late: "",
    no_of_days_absent: "",
    sss_loan: "",
    ut_late_amount: "",
    pag_ibig_loan: "",
    absent_deduction: "",
    nd_no_hrs: "",
    rd_no_hrs: "",
    nd_amount: "",
    rd_amount: "",
    nd_nrate: "",
    others: "",
    cb: "",
    lic: "",
    par: "",
    id: "",
    ric: "",
    h_c: "",
    v1: "",
    v2: "",
    no_of_days_worked: "",
    reliever_assign_id: "",
    personal_id: "",
  });
  //fetching data from database
  const { data: employeeData, isLoading: employeeLoading } =
    useGetEmployeeWPayrollQuery(
      {
        ...(isSuperAdmin === false ? { branch_id: filterDTR.branch_id } : {}),
        month: filterDTR.month,
        year: filterDTR.year,
        period: filterDTR.period,
        payroll_id: filterDTR.payroll_id,
      },
      {
        refetchOnMountOrArgChange: true,
      }
    );

  const { data: payrollGetData, isLoading: payrollLoading } =
    useGetPayrollQuery(
      {
        ...(isSuperAdmin === false ? { branch_id: filterDTR.branch_id } : {}),
        month: filterDTR.month,
        year: filterDTR.year,
        period: filterDTR.period,
        ...(isSuperAdmin === false ? { user_id: local_user_id } : {}),
        payroll_id: filterDTR.payroll_id,
      },
      { refetchOnMountOrArgChange: true }
    );

  const { data: assignmentData, isLoading: assignLoading } =
    useGetAllAssignmentQuery(
      {
        ...(isSuperAdmin === false ? { branch_id: filterDTR.branch_id } : {}),
      },
      { refetchOnMountOrArgChange: true }
    );

  //end fetching data from database

  useEffect(() => {
    if (employeeData && !employeeLoading) {
      const employeeOptions = employeeData.map((data) => ({
        sortOrder: data.last_name,
        label: `${data.last_name}, ${data.first_name}`,
        value: data.user_id,
      }));

      setEmployeeOption(employeeOptions);
    }
  }, [employeeData, employeeLoading]);

  const uniqueEmployeeOptions = employeeOptions.filter(
    (option, index, self) =>
      index === self.findIndex((t) => t.value === option.value)
  );

  useEffect(() => {
    if (payrollGetData) {
      const filteredPayrollData = payrollGetData?.filter(
        (data) =>
          `${data.first_name} ${data.last_name}`
            .toLowerCase()
            .includes(searchTerm.toLowerCase()) ||
          data.user_id.toString().includes(searchTerm)
      );

      const filterUniqueEmployee = payrollGetData.filter(
        (data, index, self) =>
          index === self.findIndex((t) => t.user_id === data.user_id)
      );

      let numberOfEmployee = filterUniqueEmployee.length;
      let totalSalary = 0;
      const createTableRow = filteredPayrollData.map((data) => {
        const isActive = activeRow === data.id; // Check if this row is active
        totalSalary += data.total_salary;
        return (
          <TableRow
            key={data.id}
            onClick={() =>
              handleRowClick(data.user_id, data?.reliever, data.id)
            } // Set this row as active on click
            style={{
              cursor: "pointer",
              backgroundColor: isActive ? "rgb(49 88 84)" : "transparent", // Highlight active row
            }}
          >
            <TableCell
              style={{
                color: isActive ? "white" : "black", // Apply white color for active row cells
              }}
            >
              {data.user_id}
            </TableCell>
            <TableCell
              style={{
                color: isActive ? "white" : "black",
              }}
            >
              {data.first_name} {data.last_name}
            </TableCell>
            <TableCell
              style={{
                color: isActive ? "white" : "black",
              }}
            >
              {data.salary_rate}
            </TableCell>
            <TableCell
              style={{
                color: isActive ? "white" : "black",
              }}
            >
              {data.total_salary}
            </TableCell>
            <TableCell>
              {data?.reliever ? (
                <>
                  <InfoIcon style={{ color: "red" }} titleAccess="Reliever" />
                  <Typography style={{ color: "red" }}>Reliever</Typography>
                </>
              ) : null}
            </TableCell>
          </TableRow>
        );
      });

      let createFooterRow = (
        <>
          <Stack direction={"row"} spacing={2}>
            <Typography style={{ fontWeight: 700, width: "25%" }}>
              Total Employee :
            </Typography>
            <Typography style={{ fontWeight: 700, width: "25%" }}>
              {numberOfEmployee}
            </Typography>
            <Typography style={{ fontWeight: 700, width: "25%" }}>
              Total Salary :
            </Typography>
            <Typography style={{ fontWeight: 700, width: "25%" }}>
              {decimalFormat(totalSalary)}
            </Typography>
          </Stack>
        </>
      );
      setTotalTable(createFooterRow);
      setEmployeeTable(createTableRow);
    }
  }, [payrollGetData, activeRow]);

  useEffect(() => {
    if (assignmentData && !assignLoading) {
      const assignData = assignmentData
        .filter((data) => data.area_name !== null)
        .map((data) => ({
          label: data.area_name,
          value: data.id,
        }));

      setAssignOptions(assignData);
    }
  }, [assignmentData, assignLoading]);

  const handleEmployeeNameChange = async (user_id, reliever, tableId) => {
    try {
      let employee;
      if (reliever === undefined) {
        employee = employeeData?.find((data) => data.user_id === user_id);
      } else {
        employee = employeeData?.find(
          (data) => data.user_id === user_id && data.reliever === reliever
        );
      }

      setActiveRow(tableId); // Set the active row
      setCheckAdjustment({
        ...checkAdjustment,
        enableCashAdvance: employee.loans?.amount ? true : false,
        reliever: reliever === 1,
      });

      setAllowAdjustment({
        updateBasicRate: false,
        enableCashAdvance: employee.loans?.amount ? true : false,
        reliever: reliever === 1,
      });

      setEmployeeName({
        label: String(employee.first_name + " " + employee.last_name),
        value: employee.user_id,
      });

      setSelectAssign({
        label: String(employee.area_assign),
        value: employee.reliever_assign_id,
      });
      checkEncodeData(employee);
      if (reliever === 1) {
        await updateEmployeeInfo(employee);
        return;
      }

      setEmployeeInfo({
        ...employeeInfo,
        employee_id: employee.user_id.toString(),
        assign_area: employee.area_assign,
        last_name: employee.last_name,
        first_name: employee.first_name,
        middle_initial: employee.middle_name,
        position: employee.position,
        salary_rate:
          parseFloat(employee.basic_salary || 0) *
          parseFloat(employee.days_work || 0),
        basic_pay: employee.basic_salary || 0,
        new_salary_rate: employee.new_salary_rate || 0,
        new_basic_pay: employee.new_basic_pay || 0,
        special_holiday: employee.special_holiday || 0,
        regular_holiday: employee.regular_holiday || 0,
        s_absent: employee.s_absent || 0,
        sss: employee.sss_rate || 0,
        phlt: employee.philhealth_rate || 0,
        pag_ibig: employee.pagibig_rate || 0,
        allowance_adj: employee.allowance_adj || 0,
        cash: employee.loans?.amount || 0,
        prev_balance: Math.abs(employee.prev_balance) || 0,
        payment: employee.loans?.monthly_payment || 0,
        remaining_balance:
          employee.prev_balance - employee.loans?.monthly_payment || 0,
        ot: employee.overtime || 0,
        ot_amount: decimalFormat(
          employee.overtime * (employee.salary_rate / 8) * 1.25 || 0
        ),
        undertime_late: employee.undertime_late || 0,
        no_of_days_absent: employee.no_of_days_absent || 0,
        sss_loan: employee.sss_loan || 0,
        ut_late_amount: employee.ut_late_amount || 0,
        pag_ibig_loan: employee.pag_ibig_loan || 0,
        absent_deduction: employee.absent_deduction || 0,
        nd_no_hrs: employee.nd || 0,
        rd_no_hrs: employee.rd || 0,
        nd_amount: decimalFormat(
          (employee.salary_rate / 8) * parseFloat(employee.nd || 0) * 1.1 || 0
        ),
        rd_amount: decimalFormat(
          (employee.salary_rate / 8) * employee.rd * 1.3 || 0
        ),
        nd_nrate: decimalFormat((employee.salary_rate / 8) * 1.1 || 0),
        others: employee.others || 0,
        cb: employee.cb || 0,
        lic: employee.lic || 0,
        par: employee.par || 0,
        ric: employee.ric || 0,
        h_c: employee.h_c || 0,
        v1: employee.v1 || 0,
        v2: employee.v2 || 0,
        no_of_days_worked: employee.days_work || 0,
        personal_id: employee.personal_id || 0,
        id: employee.id || 0,
      });

      setAllowAdjustment({
        updateBasicRate: false,
        enableCashAdvance: false,
        reliever: false,
      });
    } catch (error) {
      setError(true);
      return;
    }
  };

  const updateEmployeeInfo = async (employee) => {
    const assign_area = assignOptions.find(
      (data) => data.value === employee.assign_id
    );

    setEmployeeInfo({
      ...employeeInfo,
      employee_id: employee.user_id.toString(),
      assign_area: assign_area.label,
      last_name: employee.last_name,
      first_name: employee.first_name,
      middle_initial: employee.middle_name,
      position: employee.position,
      salary_rate:
        parseFloat(employee.basic_salary || 0) *
        parseFloat(employee.days_work || 0),
      basic_pay: employee.basic_salary || 0,
      new_salary_rate: 0,
      new_basic_pay: 0,
      special_holiday: 0,
      regular_holiday: 0,
      s_absent: 0,
      sss: 0,
      phlt: 0,
      pag_ibig: 0,
      allowance_adj: 0,
      cash: employee.advance || 0,
      prev_balance: Math.abs(employee.prev_balance) || 0,
      payment: employee.payment || 0,
      remaining_balance: 0,
      ot: 0,
      ot_amount: 0,
      undertime_late: 0,
      no_of_days_absent: 0,
      sss_loan: 0,
      ut_late_amount: 0,
      pag_ibig_loan: 0,
      absent_deduction: 0,
      nd_no_hrs: 0,
      rd_no_hrs: 0,
      nd_amount: 0,
      rd_amount: 0,
      nd_nrate: 0,
      others: 0,
      cb: 0,
      lic: 0,
      par: 0,
      ric: 0,
      h_c: 0,
      v1: 0,
      v2: 0,
      no_of_days_worked: employee.days_work || 0,
      personal_id: employee.personal_id || 0,
      id: employee.id || 0,
    });

    setAssignData({
      label: employee.area_assign,
      value: employee.reliever_assign_id,
    });

    setAllowAdjustment({
      updateBasicRate: false,
      enableCashAdvance: false,
      reliever: true,
    });
  };

  const checkEncodeData = (employee) => {
    if (isSuperAdmin === false) {
      console.log(employee?.encoded_by, local_user_id);
      if (
        local_user_id === employee?.encoded_by ||
        employee?.encoded_by === undefined ||
        employee?.encoded_by === null
      ) {
        setCheckEncoded_by(1);
      } else {
        setCheckEncoded_by(0);
      }
    } else {
      setCheckEncoded_by(1);
    }
  };

  const handleEmployeeNameNull = () => {
    setAllowAdjustment({
      updateBasicRate: true,
      enableCashAdvance: true,
      reliever: true,
    });
    setCheckEncoded_by(0);
    setSelectAssign(null);

    setActiveRow(null);
    setCheckAdjustment({
      updateBasicRate: false,
      enableCashAdvance: false,
      reliever: false,
    });
    setEmployeeInfo({
      employee_id: "",
      assign_area: "",
      last_name: "",
      first_name: "",
      middle_initial: "",
      position: "",
      salary_rate: "",
      basic_pay: "",
      new_salary_rate: "",
      new_basic_pay: "",
      special_holiday: "",
      regular_holiday: "",
      s_absent: "",
      sss: "",
      phlt: "",
      pag_ibig: "",
      allowance_adj: "",
      cash: "",
      prev_balance: "",
      payment: "",
      remaining_balance: "",
      ot: "",
      ot_amount: "",
      undertime_late: "",
      no_of_days_absent: "",
      sss_loan: "",
      ut_late_amount: "",
      pag_ibig_loan: "",
      absent_deduction: "",
      nd_no_hrs: "",
      rd_no_hrs: "",
      nd_amount: "",
      rd_amount: "",
      nd_nrate: "",
      others: "",
      cb: "",
      lic: "",
      par: "",
      id: "",
      ric: "",
      h_c: "",
      v1: "",
      v2: "",
      no_of_days_worked: "",
      personal_id: "",
    });
  };

  const handleSavePayroll = async () => {
    try {
      const payrollData = employeeInfo;
      const formData = {
        month: filterDTR.month,
        year: filterDTR.year,
        period: filterDTR.period,
        branch_id: filterDTR.branch_id,
        user_id: local_user_id,
        payroll_data: payrollData,
        new_basic_pay: checkAdjustment.updateBasicRate,
        reliever: checkAdjustment?.reliever ? 1 : 0,
        assign_id: selectAssign?.value || 0,
        payroll_id: activeRow,
        payroll_page_id: filterDTR.payroll_id,
      };

      const savePayroll = await createPayroll({
        formData,
      });
      if (savePayroll) {
        socketService.sendMessage({ type: "refresh-payroll" });
        toast.success(savePayroll.data.message);
      }
    } catch (error) {
      console.log(error);
      toast.error("Payroll not saved!");
    }
  };

  const handleRowClick = (userId, reliever, tableId) => {
    setSelectedRowData({ userId, reliever, tableId });
  };

  useEffect(() => {
    if (selectedRowData) {
      const { userId, reliever, tableId } = selectedRowData;
      handleEmployeeNameChange(userId, reliever, tableId);
    }
  }, [selectedRowData]);

  // Filter payroll data based on search term

  const handlePayrollButton = (e) => {
    const params = new URLSearchParams();
    params.append("branch_id", filterDTR.branch_id);
    params.append("month", filterDTR.month);
    params.append("year", filterDTR.year);
    params.append("period", filterDTR.period);
    // params.append("user_id", local_user_id);
    params.append("payroll_id", filterDTR.payroll_id);

    if (e.target.value === "Payroll") {
      window.location.href = `view-payroll?${params.toString()}`;
    } else if (e.target.value === "Payslip") {
      window.location.href = `view-payslip?${params.toString()}`;
    } else if (e.target.value === "13thMonth") {
      window.location.href = `/manage-13th-month-pay?${params.toString()}`;
    }
  };

  const handleCheckAdjustment = (event) => {
    setCheckAdjustment({
      ...checkAdjustment,
      [event.target.name]: event.target.checked,
    });

    if (event.target.name === "updateBasicRate") {
      if (!event.target.checked) {
        const employee = employeeData.find(
          (data) => data.user_id === employeeName.user_id
        );

        if (employee) {
          const prevBasicPay = employee.basic_salary;
          const prevSalaryRate =
            parseFloat(employee.basic_salary) * parseFloat(employee.days_work);

          setEmployeeInfo({
            ...employeeInfo,
            basic_pay: prevBasicPay,
            salary_rate: prevSalaryRate,
          });
        }
      }
    }

    if (event.target.name === "reliever") {
      if (event.target.checked) {
        setCheckEncoded_by(1);
        setEmployeeInfo({
          ...employeeInfo,
          reliever_assign_id: assign_id,
          reliever: 1,
          salary_rate: 0,
          new_salary_rate: 0,
          new_basic_pay: 0,
          special_holiday: 0,
          regular_holiday: 0,
          s_absent: 0,
          sss: 0,
          phlt: 0,
          pag_ibig: 0,
          allowance_adj: 0,
          cash: 0,
          prev_balance: 0,
          payment: 0,
          remaining_balance: 0,
          ot: 0,
          ot_amount: 0,
          undertime_late: 0,
          no_of_days_absent: 0,
          sss_loan: 0,
          ut_late_amount: 0,
          pag_ibig_loan: 0,
          absent_deduction: 0,
          nd_no_hrs: 0,
          rd_no_hrs: 0,
          nd_amount: 0,
          rd_amount: 0,
          nd_nrate: 0,
          others: 0,
          cb: 0,
          lic: 0,
          par: 0,
          ric: 0,
          h_c: 0,
          v1: 0,
          v2: 0,
          no_of_days_worked: 0,
        });
      } else {
        const employee = employeeData.find(
          (data) => data.user_id === employeeName.value
        );
        checkEncodeData(employee);
        setEmployeeInfo({
          ...employeeInfo,
          reliever_assign_id: 0,
          reliever: 0,
          salary_rate:
            parseFloat(employee.basic_salary) * parseFloat(employee.days_work),
          new_salary_rate: employee?.new_salary_rate || 0,
          new_basic_pay: employee?.new_basic_pay || 0,
          special_holiday: employee?.special_holiday || 0,
          regular_holiday: employee?.regular_holiday || 0,
          s_absent: employee?.s_absent || 0,
          sss: employee?.sss_rate || 0,
          phlt: employee?.philhealth_rate || 0,
          pag_ibig: employee?.pagibig_rate || 0,
          allowance_adj: employee?.allowance_adj || 0,
          cash: decimalFormat(employee?.advance || 0),
          prev_balance: Math.abs(employee?.prev_balance) || 0,
          payment: employee?.payment || 0,
          remaining_balance: employee?.remaining_balance || 0,
          ot: employee?.overtime || 0,
          ot_amount: decimalFormat(
            employee?.overtime * (employee?.salary_rate / 8) * 1.25 || 0
          ),
          undertime_late: employee?.undertime_late || 0,
          no_of_days_absent: employee?.no_of_days_absent || 0,
          sss_loan: employee?.sss_loan || 0,
          ut_late_amount: employee?.ut_late_amount || 0,
          pag_ibig_loan: employee?.pag_ibig_loan || 0,
          absent_deduction: employee?.absent_deduction || 0,
          nd_no_hrs: employee?.nd || 0,
          rd_no_hrs: employee?.rd || 0,
          nd_amount: decimalFormat(
            (employee?.salary_rate / 8) * parseFloat(employee?.nd || 0) * 1.1 ||
              0
          ),
          rd_amount: decimalFormat(
            (employee?.salary_rate / 8) * employee?.rd * 1.3 || 0
          ),
          nd_nrate: decimalFormat((employee?.salary_rate / 8) * 1.1 || 0),
          others: employee?.others || 0,
          cb: employee?.cb || 0,
          lic: employee?.lic || 0,
          par: employee?.par || 0,
          id: employee?.id || 0,
          ric: employee?.ric || 0,
          h_c: employee?.h_c || 0,
          v1: employee?.v1 || 0,
          v2: employee?.v2 || 0,
          no_of_days_worked: employee?.days_work || 0,
          personal_id: employee?.personal_id || 0,
        });
      }
    }
  };

  const handleCreatePayroll = async (data) => {
    try {
      const formData = {
        month: data.months,
        year: filterDTR.year,
        period: data.period,
        branch_id: filterDTR.branch_id,
        user_id: local_user_id,
        name: data.payroll_name,
      };

      const createPayroll = await createPayrollHeader({
        formData,
      });

      if (createPayroll?.data?.payroll_id) {
        setPayrollNumber(createPayroll.data.payroll_id);
        setFilterDTR({
          ...filterDTR,
          payroll_id: createPayroll.data.payroll_id,
          payroll_name: createPayroll.data.name,
        });
        socketService.sendMessage({ type: "refresh" });
        //toast.success(createPayroll.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeletePayroll = async () => {
    try {
      const formData = {
        month: filterDTR.month,
        year: filterDTR.year,
        period: filterDTR.period,
        branch_id: filterDTR.branch_id,
        user_id: filterDTR.user_id,
        employee_id: employeeInfo.employee_id,
      };

      console.log(formData);
      /*const deletePayroll = await createPayroll({
        formData,
      });

      if (deletePayroll) {
        socketService.sendMessage({ type: "refresh-payroll" });
        toast.success(deletePayroll.data.message);
      }*/
    } catch (error) {
      toast.error("Payroll not deleted!");
    }
  };

  return (
    <>
      <Paper elevation={3} sx={{ width: "100%" }} className="createPayrollNew">
        {employeeLoading && payrollLoading && assignLoading ? (
          <CircularProgress
            size="3rem"
            style={{ display: employeeLoading ? "block" : "none" }}
          />
        ) : (
          <>
            <Grid container>
              <Grid item xs={12}>
                <Stack direction={"row"} spacing={2} sx={{ width: "100%" }}>
                  <Stack
                    direction={"column"}
                    spacing={2}
                    p={2}
                    sx={{ width: "50%" }}
                  >
                    <Typography style={{ marginTop: "0px" }}>
                      Payroll Number : <strong>{filterDTR.payroll_id}</strong>
                    </Typography>
                    <Typography style={{ marginTop: "0px" }}>
                      Payroll Name: <strong>{filterDTR.payroll_name}</strong>
                    </Typography>
                    {/*<Typography style={{ marginTop: "0px" }}>
                      Assign Area: <strong>{area_assign}</strong>
                    </Typography>*/}
                  </Stack>
                  <Stack
                    direction={"column"}
                    spacing={2}
                    p={2}
                    sx={{ width: "50%" }}
                  >
                    <Typography style={{ marginTop: "0px" }}>
                      Payroll Period:{" "}
                      <strong>
                        {" "}
                        {monthData.find(
                          (data) => data.value === parseInt(filterDTR.month)
                        )?.label || "Unknown Month"}{" "}
                        {filterDTR.year}
                        {"/"}
                        {parseInt(filterDTR.period) === 1
                          ? "1-15th"
                          : "16th-end"}{" "}
                      </strong>
                    </Typography>
                  </Stack>
                  <Stack sx={{ padding: 2 }}>
                    <a href="/manage-payroll">
                      <Button
                        variant="contained"
                        color="primary"
                        startIcon={<BackwardIcon />}
                      >
                        Back
                      </Button>
                    </a>
                  </Stack>
                </Stack>
              </Grid>
              <Grid item xs={12}>
                <Card sx={{ padding: 2 }}>
                  <Stack direction={"row"} spacing={2}>
                    <Stack direction={"row"} spacing={2} width={"61%"}>
                      <FormControl
                        component="fieldset"
                        style={{
                          border: "2px solid rgb(0, 0, 0, 0.12)",
                          width: "100%",
                        }}
                      >
                        <FormLabel
                          component="legend"
                          style={{ textAlign: "center" }}
                        >
                          Payroll Adjustments
                        </FormLabel>
                        <FormGroup aria-label="position" row>
                          <FormControlLabel
                            value="bottom"
                            control={
                              <Checkbox
                                value={checkAdjustment.updateBasicRate}
                                onChange={handleCheckAdjustment}
                                name="updateBasicRate"
                                disabled={allowAdjustment.updateBasicRate}
                                checked={checkAdjustment.updateBasicRate}
                              />
                            }
                            label="Update Basic Rate"
                            labelPlacement="bottom"
                          />
                          <FormControlLabel
                            value="bottom"
                            control={
                              <Checkbox
                                value={checkAdjustment.enableCashAdvance}
                                onChange={handleCheckAdjustment}
                                name="enableCashAdvance"
                                disabled={allowAdjustment.enableCashAdvance}
                                checked={checkAdjustment.enableCashAdvance}
                              />
                            }
                            label="Enable Cash Advance"
                            labelPlacement="bottom"
                          />
                          <FormControlLabel
                            value="bottom"
                            control={
                              <Checkbox
                                value={checkAdjustment.reliever || false}
                                onChange={handleCheckAdjustment}
                                name="reliever"
                                disabled={allowAdjustment.reliever}
                                checked={checkAdjustment.reliever}
                              />
                            }
                            label="Reliever"
                            labelPlacement="bottom"
                          />
                        </FormGroup>
                      </FormControl>
                    </Stack>
                    <Stack
                      direction={"row"}
                      spacing={2}
                      sx={{
                        width: "45%",
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "flex-end",
                      }}
                    >
                      {/*checkEncoded_by === local_user_id ? (*/}
                      <LoadingButton
                        variant="contained"
                        color="primary"
                        onClick={handleSavePayroll}
                        loading={employeeLoading}
                        disabled={checkEncoded_by === 1 ? false : true}
                        sx={{
                          height: "61px",
                          backgroundColor: "#4caf50",
                          color: "white",
                        }}
                      >
                        Save
                      </LoadingButton>{" "}
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handlePayrollButton}
                        value="Payroll"
                        sx={{ height: "61px", backgroundColor: "#2196f3" }}
                      >
                        View Payroll
                      </Button>{" "}
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handlePayrollButton}
                        value="Payslip"
                        sx={{ height: "61px", backgroundColor: "#2196f3" }}
                      >
                        View PaySlip
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handlePayrollButton}
                        value="13thMonth"
                        sx={{ height: "61px", backgroundColor: "#2196f3" }}
                      >
                        View 13th Month Pay
                      </Button>
                    </Stack>
                  </Stack>
                </Card>
              </Grid>
              <Grid item xs={12} md={5}>
                <Card sx={{ padding: 2 }}>
                  <Stack spacing={2}>
                    <Stack direction={"row"} spacing={2}>
                      <Autocomplete
                        options={uniqueEmployeeOptions}
                        getOptionLabel={(option) => option.label} // Display the label
                        value={employeeName}
                        className="employeeName"
                        onChange={(event, newValue) => {
                          // Access the selected value here
                          if (newValue) {
                            setEmployeeName(newValue); // Set the selected value
                            setSelectedValue(newValue); // Set the selected value
                            handleEmployeeNameChange(newValue?.value); // Use the `value` field of the selected option
                          } else {
                            // If the selection is cleared
                            console.log("Cleared");
                            setEmployeeName(null);
                            setSelectedValue(null);
                            handleEmployeeNameNull(); // Reset or handle null employee name
                          }
                        }}
                        required
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            sx={{
                              textAlign: "center",
                              fontWeight: 700,
                              width: "181px",
                            }}
                            label="Search Employee Name"
                            fullWidth
                            placeholder="Search Employee Name"
                            required
                            className="employeeName"
                            error={error && !employeeInfo.employee_id}
                            helperText={
                              error && !employeeInfo.employee_id
                                ? "employee ID is required"
                                : ""
                            }
                          />
                        )}
                        isOptionEqualToValue={(option, value) =>
                          option.label === String(value)
                        } // This ensures correct value matching
                      />

                      <TextField
                        label="Area Assignment"
                        fullWidth
                        variant="outlined"
                        value={employeeInfo.assign_area}
                        onChange={(e) => {
                          setEmployeeInfo({
                            ...employeeInfo,
                            assign_area: e.target.value,
                          });
                        }}
                        disabled={true}
                      />
                    </Stack>
                    <Stack direction={"row"} spacing={2}>
                      <TextField
                        label="Last Name"
                        variant="outlined"
                        value={employeeInfo.last_name}
                        onChange={(e) => {
                          setEmployeeInfo({
                            ...employeeInfo,
                            last_name: e.target.value,
                          });
                        }}
                        disabled={true}
                      />
                      <TextField
                        label="First Name"
                        variant="outlined"
                        value={employeeInfo.first_name}
                        disabled={true}
                      />
                      <TextField
                        label="M.I."
                        variant="outlined"
                        value={employeeInfo.middle_initial}
                        disabled={true}
                      />
                    </Stack>
                    <Stack direction={"row"} spacing={2}>
                      <TextField
                        label="Position"
                        variant="outlined"
                        value={employeeInfo.position}
                        disabled={true}
                      />
                      <TextField
                        label="Salary Rate"
                        variant="outlined"
                        value={employeeInfo.salary_rate}
                        onChange={(e) => {
                          setEmployeeInfo({
                            ...employeeInfo,
                            salary_rate: e.target.value,
                          });
                        }}
                        disabled={true}
                      />
                      <TextField
                        label="Basic Pay"
                        variant="outlined"
                        value={employeeInfo.basic_pay}
                        onChange={(e) => {
                          setEmployeeInfo({
                            ...employeeInfo,
                            basic_pay: e.target.value,
                            salary_rate:
                              parseFloat(e.target.value) *
                              parseFloat(employeeInfo.no_of_days_worked),
                          });
                        }}
                        disabled={
                          checkAdjustment.updateBasicRate ? false : true
                        }
                        className={
                          checkAdjustment.updateBasicRate ? "adjustFont" : ""
                        }
                      />
                    </Stack>
                    <Stack direction={"row"} spacing={2}>
                      <TextField
                        label="Special Holiday"
                        variant="outlined"
                        value={employeeInfo.special_holiday}
                        onChange={(e) => {
                          setEmployeeInfo({
                            ...employeeInfo,
                            special_holiday: e.target.value,
                          });
                        }}
                        disabled={checkAdjustment.reliever ? true : false}
                      />
                      <TextField
                        label="Regular Holiday"
                        variant="outlined"
                        value={employeeInfo.regular_holiday}
                        onChange={(e) => {
                          setEmployeeInfo({
                            ...employeeInfo,
                            regular_holiday: e.target.value,
                          });
                        }}
                        disabled={checkAdjustment.reliever ? true : false}
                      />
                      <TextField
                        label="S-Absent"
                        variant="outlined"
                        value={employeeInfo.s_absent}
                        onChange={(e) => {
                          setEmployeeInfo({
                            ...employeeInfo,
                            s_absent: e.target.value,
                          });
                        }}
                        disabled={checkAdjustment.reliever ? true : false}
                      />
                    </Stack>
                    <Stack direction={"row"} spacing={2}>
                      <TextField
                        label="SSS"
                        variant="outlined"
                        value={employeeInfo.sss}
                        onChange={(e) => {
                          setEmployeeInfo({
                            ...employeeInfo,
                            sss: e.target.value,
                          });
                        }}
                        disabled={checkAdjustment.reliever ? true : false}
                      />
                      <TextField
                        label="PHLT"
                        variant="outlined"
                        value={employeeInfo.phlt}
                        onChange={(e) => {
                          setEmployeeInfo({
                            ...employeeInfo,
                            phlt: e.target.value,
                          });
                        }}
                        disabled={checkAdjustment.reliever ? true : false}
                      />
                      <TextField
                        label="PAG-IBIG"
                        variant="outlined"
                        value={employeeInfo.pag_ibig}
                        onChange={(e) => {
                          setEmployeeInfo({
                            ...employeeInfo,
                            pag_ibig: e.target.value,
                          });
                        }}
                        disabled={checkAdjustment.reliever ? true : false}
                      />
                      <TextField
                        label="Allowance/Adj"
                        variant="outlined"
                        value={employeeInfo.allowance_adj}
                        onChange={(e) => {
                          setEmployeeInfo({
                            ...employeeInfo,
                            allowance_adj: e.target.value,
                          });
                        }}
                        disabled={checkAdjustment.reliever ? true : false}
                      />
                    </Stack>
                    <Stack direction={"row"} spacing={2}>
                      <Stack direction={"row"} spacing={2}>
                        <fieldset>
                          <legend>CASH ADVANCE</legend>
                          <Stack spacing={2}>
                            <Stack direction={"row"} spacing={2}>
                              <TextField
                                label="CASH"
                                variant="outlined"
                                value={employeeInfo.cash}
                                onChange={(e) => {
                                  setEmployeeInfo({
                                    ...employeeInfo,
                                    cash: e.target.value,
                                    remaining_balance:
                                      parseFloat(e.target.value) -
                                      parseFloat(employeeInfo.payment),
                                  });
                                }}
                                disabled={
                                  checkAdjustment.enableCashAdvance
                                    ? false
                                    : true
                                }
                                className={
                                  checkAdjustment.enableCashAdvance
                                    ? "adjustFont"
                                    : ""
                                }
                              />
                              <TextField
                                label="PREV. Balance"
                                variant="outlined"
                                value={Math.abs(employeeInfo.prev_balance)}
                                onChange={(e) => {
                                  setEmployeeInfo({
                                    ...employeeInfo,
                                    prev_balance: e.target.value,
                                  });
                                }}
                                disabled={true}
                              />
                            </Stack>
                            <Stack direction={"row"} spacing={2}>
                              <TextField
                                label="PAYMENT"
                                variant="outlined"
                                value={employeeInfo.payment}
                                onChange={(e) => {
                                  setEmployeeInfo({
                                    ...employeeInfo,
                                    payment: e.target.value,
                                    remaining_balance: employeeInfo.prev_balance
                                      ? parseFloat(employeeInfo.prev_balance) -
                                        parseFloat(e.target.value)
                                      : parseFloat(employeeInfo.cash) -
                                        parseFloat(e.target.value),
                                  });
                                }}
                                disabled={
                                  checkAdjustment.enableCashAdvance
                                    ? false
                                    : true
                                }
                                className={
                                  checkAdjustment.enableCashAdvance
                                    ? "adjustFont"
                                    : ""
                                }
                              />
                              <TextField
                                label="REMAINING BALANCE"
                                variant="outlined"
                                value={employeeInfo.remaining_balance}
                                onChange={(e) => {
                                  setEmployeeInfo({
                                    ...employeeInfo,
                                    remaining_balance: e.target.value,
                                  });
                                }}
                                disabled={true}
                              />
                            </Stack>
                          </Stack>
                        </fieldset>
                      </Stack>
                      <Stack spacing={2}>
                        <fieldset>
                          <legend>DEDUCTION</legend>
                          <Stack spacing={2}>
                            <Stack direction={"row"} spacing={2}>
                              <TextField
                                label="UNDERTIME/LATE"
                                variant="outlined"
                                value={employeeInfo.undertime_late}
                                onChange={(e) => {
                                  setEmployeeInfo({
                                    ...employeeInfo,
                                    undertime_late: e.target.value,
                                  });
                                }}
                              />
                              <TextField
                                label="No. of Days Absent"
                                variant="outlined"
                                value={employeeInfo.no_of_days_absent}
                                onChange={(e) => {
                                  setEmployeeInfo({
                                    ...employeeInfo,
                                    no_of_days_absent: e.target.value,
                                  });
                                }}
                                disabled={
                                  checkAdjustment.reliever ? true : false
                                }
                              />
                            </Stack>
                            <Stack direction={"row"} spacing={2}>
                              <TextField
                                label="SSS Loan"
                                variant="outlined"
                                value={employeeInfo.sss_loan}
                                onChange={(e) => {
                                  setEmployeeInfo({
                                    ...employeeInfo,
                                    sss_loan: e.target.value,
                                  });
                                }}
                                disabled={
                                  checkAdjustment.reliever ? true : false
                                }
                              />
                              <TextField
                                label="UT/Late Amount"
                                variant="outlined"
                                value={employeeInfo.ut_late_amount}
                                onChange={(e) => {
                                  setEmployeeInfo({
                                    ...employeeInfo,
                                    ut_late_amount: e.target.value,
                                  });
                                }}
                              />
                            </Stack>
                            <Stack direction={"row"} spacing={2}>
                              <TextField
                                label="PAG-IBIG Loan"
                                variant="outlined"
                                value={employeeInfo.pag_ibig_loan}
                                onChange={(e) => {
                                  setEmployeeInfo({
                                    ...employeeInfo,
                                    pag_ibig_loan: e.target.value,
                                  });
                                }}
                                disabled={
                                  checkAdjustment.reliever ? true : false
                                }
                              />
                              <TextField
                                label="Absent Deduction"
                                variant="outlined"
                                value={employeeInfo.absent_deduction}
                                onChange={(e) => {
                                  setEmployeeInfo({
                                    ...employeeInfo,
                                    absent_deduction: e.target.value,
                                  });
                                }}
                                disabled={
                                  checkAdjustment.reliever ? true : false
                                }
                              />
                            </Stack>
                          </Stack>
                        </fieldset>
                      </Stack>
                    </Stack>
                  </Stack>
                </Card>
              </Grid>
              <Grid item xs={12} md={2}>
                <Card sx={{ padding: 2 }} style={{ height: "100%" }}>
                  <Stack spacing={2}>
                    {checkAdjustment.reliever === true &&
                    (roles.includes(999) || roles.includes(100)) ? (
                      <Stack direction={"row"} spacing={2}>
                        <Autocomplete
                          options={assignOptions}
                          getOptionLabel={(option) => option.label} // Display the label
                          value={selectAssign}
                          fullWidth
                          className={
                            checkAdjustment.reliever ? "adjustFont" : ""
                          }
                          onChange={(event, newValue) => {
                            // Access the selected value here
                            if (newValue) {
                              setSelectAssign(newValue); // Set the selected value
                              //setSelectedValue(newValue); // Set the selected value
                              //handleEmployeeNameChange(newValue?.value); // Use the `value` field of the selected option
                            } else {
                              // If the selection is cleared
                              setSelectAssign(null);
                              //handleEmployeeNameNull(); // Reset or handle null employee name
                            }
                          }}
                          required
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              sx={{
                                textAlign: "center",
                                fontWeight: 700,
                                width: "100%",
                              }}
                              label="Reliever Assign"
                              fullWidth
                              placeholder="Reliever Assign"
                            />
                          )}
                          isOptionEqualToValue={(option, value) =>
                            option.label === String(value)
                          } // This ensures correct value matching
                        />
                      </Stack>
                    ) : null}
                    <Stack direction={"row"} spacing={2}>
                      <TextField
                        label="No of days worked"
                        variant="outlined"
                        fullWidth
                        value={employeeInfo.no_of_days_worked}
                        onChange={(e) => {
                          setEmployeeInfo({
                            ...employeeInfo,
                            no_of_days_worked: e.target.value,
                            salary_rate:
                              parseFloat(e.target.value) *
                              parseFloat(employeeInfo.basic_pay),
                          });
                        }}
                        className={checkAdjustment.reliever ? "adjustFont" : ""}
                      />
                    </Stack>
                    <Stack direction={"row"} spacing={2}>
                      <TextField
                        label="ND No. Hrs"
                        variant="outlined"
                        value={employeeInfo.nd_no_hrs}
                        onChange={(e) => {
                          setEmployeeInfo({
                            ...employeeInfo,
                            nd_no_hrs: e.target.value,
                            nd_amount: decimalFormat(
                              parseFloat(employeeInfo.basic_pay / 8) *
                                parseFloat(e.target.value || 0) *
                                1.1
                            ),
                          });
                        }}
                      />
                      <TextField
                        label="RD No. Hrs"
                        variant="outlined"
                        value={employeeInfo.rd_no_hrs}
                        onChange={(e) => {
                          setEmployeeInfo({
                            ...employeeInfo,
                            rd_no_hrs: e.target.value,
                            rd_amount: decimalFormat(
                              parseFloat(employeeInfo.basic_pay / 8) *
                                e.target.value *
                                1.3
                            ),
                          });
                        }}
                      />
                    </Stack>
                    <Stack direction={"row"} spacing={2}>
                      <TextField
                        label="ND Amount"
                        variant="outlined"
                        value={employeeInfo.nd_amount}
                        onChange={(e) => {
                          setEmployeeInfo({
                            ...employeeInfo,
                            nd_amount: e.target.value,
                          });
                        }}
                        disabled={true}
                      />
                      <TextField
                        label="RD Amount"
                        variant="outlined"
                        value={employeeInfo.rd_amount}
                        onChange={(e) => {
                          setEmployeeInfo({
                            ...employeeInfo,
                            rd_amount: e.target.value,
                          });
                        }}
                        disabled={true}
                      />
                    </Stack>
                    <Stack direction={"row"} spacing={2}>
                      <TextField
                        label="ND NRate"
                        variant="outlined"
                        value={employeeInfo.nd_nrate}
                        onChange={(e) => {
                          setEmployeeInfo({
                            ...employeeInfo,
                            nd_nrate: e.target.value,
                          });
                        }}
                      />
                      <TextField
                        label="OTHERS"
                        variant="outlined"
                        value={employeeInfo.others}
                        onChange={(e) => {
                          setEmployeeInfo({
                            ...employeeInfo,
                            others: e.target.value,
                          });
                        }}
                      />
                    </Stack>
                    <Stack direction={"row"} spacing={2}>
                      <TextField
                        label="OT"
                        variant="outlined"
                        value={employeeInfo.ot}
                        onChange={(e) => {
                          setEmployeeInfo({
                            ...employeeInfo,
                            ot: e.target.value,
                          });
                        }}
                      />
                      <TextField
                        label="OT Amount"
                        variant="outlined"
                        value={employeeInfo.ot_amount}
                        onChange={(e) => {
                          setEmployeeInfo({
                            ...employeeInfo,
                            ot_amount: e.target.value,
                          });
                        }}
                        disabled={true}
                      />
                    </Stack>
                    <Stack direction={"row"} spacing={2}>
                      <TextField
                        label="CB"
                        variant="outlined"
                        value={employeeInfo.cb}
                        onChange={(e) => {
                          setEmployeeInfo({
                            ...employeeInfo,
                            cb: e.target.value,
                          });
                        }}
                      />
                      <TextField
                        label="LIC"
                        variant="outlined"
                        value={employeeInfo.lic}
                        onChange={(e) => {
                          setEmployeeInfo({
                            ...employeeInfo,
                            lic: e.target.value,
                          });
                        }}
                      />
                    </Stack>
                    <Stack direction={"row"} spacing={2}>
                      <TextField
                        label="PAR"
                        variant="outlined"
                        value={employeeInfo.par}
                        onChange={(e) => {
                          setEmployeeInfo({
                            ...employeeInfo,
                            par: e.target.value,
                          });
                        }}
                      />
                      <TextField
                        label="I.D."
                        variant="outlined"
                        value={employeeInfo.personal_id}
                        onChange={(e) => {
                          setEmployeeInfo({
                            ...employeeInfo,
                            personal_id: e.target.value,
                          });
                        }}
                      />
                    </Stack>
                    <Stack direction={"row"} spacing={2}>
                      <TextField
                        label="RIC"
                        variant="outlined"
                        value={employeeInfo.ric}
                        onChange={(e) => {
                          setEmployeeInfo({
                            ...employeeInfo,
                            ric: e.target.value,
                          });
                        }}
                      />
                      <TextField
                        label="H-C"
                        variant="outlined"
                        value={employeeInfo.h_c}
                        onChange={(e) => {
                          setEmployeeInfo({
                            ...employeeInfo,
                            h_c: e.target.value,
                          });
                        }}
                      />
                    </Stack>
                    <Stack direction={"row"} spacing={2}>
                      <TextField
                        label="V1"
                        variant="outlined"
                        value={employeeInfo.v1}
                        onChange={(e) => {
                          setEmployeeInfo({
                            ...employeeInfo,
                            v1: e.target.value,
                          });
                        }}
                      />
                      <TextField
                        label="V2"
                        variant="outlined"
                        value={employeeInfo.v2}
                        onChange={(e) => {
                          setEmployeeInfo({
                            ...employeeInfo,
                            v2: e.target.value,
                          });
                        }}
                      />
                    </Stack>
                  </Stack>
                </Card>
              </Grid>
              <Grid item xs={12} md={5}>
                <Card sx={{ padding: 2 }}>
                  <Box>
                    <TextField
                      label="Search Employee"
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)} // Update search term on input change
                      style={{ padding: "2px" }}
                      variant="standard"
                    />
                  </Box>
                  <TableContainer
                    component={Paper}
                    sx={{ maxHeight: "423px", overflow: "auto" }}
                  >
                    <Table className="createTablePayrollNew">
                      <TableHead className="stick">
                        <TableRow>
                          <TableCell>Employee ID</TableCell>
                          <TableCell>Name</TableCell>
                          <TableCell>Basic Salary</TableCell>
                          <TableCell>Total Salary</TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {employeeTable.length > 0 ? (
                          employeeTable
                        ) : (
                          <TableRow>
                            <TableCell colSpan={4} align="center">
                              No results found
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Box sx={{ padding: 2 }}>{totalTable}</Box>
                </Card>
              </Grid>
            </Grid>
            <ToastContainer />
            <ConfirmationDialog
              open={isBackOpen}
              setOpen={setIsBackOpen}
              handleSubmit={handleCreatePayroll}
            />
          </>
        )}
      </Paper>
    </>
  );
}

export default CreatePayrollN;
