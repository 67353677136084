import React, { useEffect, useState } from "react";
import {
  Grid,
  Stack,
  Typography,
  TextField,
  FormControl,
  Checkbox,
  FormControlLabel,
  Button,
  Autocomplete,
} from "@mui/material";
import { useGetEmployeeWPayrollQuery } from "../../../../app/api/users/usersApiSlice";
import useAuth from "../../../../hooks/useAuth";

function LeaveFormData({ handleSaveLeave }) {
  const { isSuperAdmin, branch_id } = useAuth();
  const [error, setError] = React.useState(false);
  const [formErrors, setFormErrors] = React.useState({});
  const [leaveForms, setLeaveForms] = React.useState({
    user_id: "",
    leave_type: "",
    start_date: "",
    end_date: "",
    leave_reason: "",
    sick_leave: false,
    vacation_leave: false,
    maternity_leave: false,
    special_leave: false,
  });
  const [selectedValue, setSelectedValue] = React.useState(null);

  const [countLeaveDuration, setCountLeaveDuration] = React.useState(0);
  const [employeeOptions, setEmployeeOption] = React.useState([]);

  const { data: employeeData, isLoading: employeeLoading } =
    useGetEmployeeWPayrollQuery(
      { ...(isSuperAdmin === false ? { branch_id: branch_id } : {}) },
      {
        refetchOnMountOrArgChange: true,
      }
    );

  useEffect(() => {
    if (employeeData && !employeeLoading) {
      const employeeOptions = employeeData.map((data) => ({
        sortOrder: data.last_name,
        label: `${data.last_name}, ${data.first_name}`,
        value: data.user_id,
        id: data.id,
      }));

      setEmployeeOption(employeeOptions);
    }
  }, [employeeData, employeeLoading]);

  const uniqueEmployeeOptions = employeeOptions.filter(
    (option, index, self) =>
      index === self.findIndex((t) => t.value === option.value)
  );
  const handleTextChange = (field, value) => {
    console.log("field", field, "value", value);
    setLeaveForms({
      ...leaveForms,
      [field]: value,
    });
  };

  useEffect(() => {
    if (leaveForms.start_date && leaveForms.end_date) {
      const calculateLeaveDuration = (start, end) => {
        const startDate = new Date(start);
        const endDate = new Date(end);

        let count = 0;
        let currentDate = new Date(startDate);

        while (currentDate <= endDate) {
          const dayOfWeek = currentDate.getDay();
          /*if (dayOfWeek !== 0 && dayOfWeek !== 6) {
            // Exclude Sunday (0) and Saturday (6)
            count++;
          }*/
          count++;
          currentDate.setDate(currentDate.getDate() + 1); // Move to the next day
        }

        return `${count} day${count > 1 ? "s" : ""}`;
      };

      const duration = calculateLeaveDuration(
        leaveForms.start_date,
        leaveForms.end_date
      );
      setCountLeaveDuration(duration);
    }
  }, [leaveForms.start_date, leaveForms.end_date]);

  const validateForm = () => {
    let errors = {};
    let formIsValid = true;

    if (!leaveForms.user_id) {
      formIsValid = false;
      errors["user_id"] = "Employee ID is required";
    }

    if (!leaveForms.start_date) {
      formIsValid = false;
      errors["start_date"] = "Start date is required";
    }

    if (!leaveForms.end_date) {
      formIsValid = false;
      errors["end_date"] = "End date is required";
    }

    if (!leaveForms.leave_reason) {
      formIsValid = false;
      errors["leave_reason"] = "Leave reason is required";
    }

    setFormErrors(errors);
    return formIsValid;
  };

  const handleSave = async () => {
    try {
      if (!validateForm()) return;
      handleSaveLeave(leaveForms);
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      direction="column"
    >
      <Stack
        direction="column"
        spacing={0}
        sx={{
          alignItems: "center", // Center Stack content horizontally within Grid
          width: "100%",
        }}
      >
        <Stack
          direction="row"
          spacing={0}
          sx={{
            justifyContent: "left",
            alignItems: "left",
            width: "100%",
            paddingBlock: "4px",
          }}
        >
          <Grid item xs={12} sm={10} md={3}>
            <Typography variant="h6" align="left">
              Employee Name :
            </Typography>
          </Grid>
          <Grid item xs={12} sm={10} md={6}>
            <FormControl
              variant="standard"
              sx={{ width: "100%", justifyContent: "center" }}
            >
              <Autocomplete
                options={uniqueEmployeeOptions}
                getOptionLabel={(option) => option.label} // Display the label
                value={selectedValue}
                className="employeeName"
                fullWidth
                onChange={(event, newValue) => {
                  // Access the selected value here
                  if (newValue) {
                    //setEmployeeName(newValue); // Set the selected value
                    setSelectedValue(newValue); // Set the selected value
                    setLeaveForms({
                      ...leaveForms,
                      user_id: newValue?.id,
                    });
                    //handleEmployeeNameChange(newValue?.value); // Use the `value` field of the selected option
                  } else {
                    // If the selection is cleared
                    //console.log("Cleared");
                    //setEmployeeName(null);
                    setLeaveForms({
                      ...leaveForms,
                      user_id: "",
                    });
                    setSelectedValue(null);
                    //handleEmployeeNameNull(); // Reset or handle null employee name
                  }
                }}
                required
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    sx={{
                      textAlign: "center",
                      fontWeight: 700,
                    }}
                    //label="Search Employee Name"
                    fullWidth
                    //placeholder="Search Employee Name"
                    required
                    className="employeeName"
                    error={error && !leaveForms.user_id}
                    helperText={
                      error && !leaveForms.user_id
                        ? "employee ID is required"
                        : ""
                    }
                  />
                )}
                isOptionEqualToValue={(option, value) =>
                  option.label === String(value)
                } // This ensures correct value matching
              />
            </FormControl>
          </Grid>
        </Stack>
        <Stack
          direction="row"
          spacing={0}
          sx={{
            justifyContent: "left",
            alignItems: "left",
            width: "100%",
            paddingBlock: "4px",
          }}
        >
          <Grid item xs={12} sm={10} md={3}>
            <Typography variant="h6" align="left">
              Leave Type :
            </Typography>
          </Grid>
          <Grid item xs={12} sm={10} md={6}>
            <Stack direction={"row"} sx={{ width: "100%" }} spacing={0}>
              <Stack direction="column" sx={{ width: "100%" }} spacing={0}>
                <FormControl
                  variant="standard"
                  sx={{ width: "100%", justifyContent: "center" }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={leaveForms.sick_leave}
                        onClick={(e) =>
                          handleTextChange("sick_leave", e.target.checked)
                        }
                      />
                    }
                    label="Sick Leave"
                    sx={{ width: "100%" }}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={leaveForms.maternity_leave}
                        onClick={(e) =>
                          handleTextChange("maternity_leave", e.target.checked)
                        }
                      />
                    }
                    label="Maternity Leave"
                    sx={{ width: "100%" }}
                  />
                </FormControl>
              </Stack>
              <Stack direction="column" sx={{ width: "100%" }} spacing={0}>
                <FormControl
                  variant="standard"
                  sx={{ width: "100%", justifyContent: "center" }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={leaveForms.vacation_leave}
                        onClick={(e) =>
                          handleTextChange("vacation_leave", e.target.checked)
                        }
                      />
                    }
                    label="Vacation Leave"
                    sx={{ width: "100%" }}
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={leaveForms.special_leave}
                        onClick={(e) =>
                          handleTextChange("special_leave", e.target.checked)
                        }
                      />
                    }
                    label="Special Leave"
                    sx={{ width: "100%" }}
                  />
                </FormControl>
              </Stack>
            </Stack>
          </Grid>
        </Stack>
        <Stack
          direction="row"
          spacing={0}
          sx={{
            justifyContent: "left",
            alignItems: "left",
            width: "100%",
            paddingBlock: "4px",
          }}
        >
          <Grid item xs={12} sm={10} md={3}>
            <Typography variant="h6" align="left">
              Start Date :
            </Typography>
          </Grid>
          <Grid item xs={12} sm={10} md={6}>
            <FormControl
              variant="standard"
              sx={{ width: "100%", justifyContent: "center" }}
            >
              <TextField
                id="standard-position"
                type="date"
                variant="standard"
                value={leaveForms.start_date}
                onChange={(e) => handleTextChange("start_date", e.target.value)}
                inputProps={{
                  min: new Date().toISOString().split("T")[0], // Set minimum date to today's date
                }}
                sx={{
                  "& .MuiInput-root": {
                    borderBottom: "1px solid", // Fixed bold underline
                  },
                }}
                error={error && !leaveForms.start_date}
                helperText={
                  error && !leaveForms.start_date
                    ? "Start date is required"
                    : ""
                }
              />
            </FormControl>
          </Grid>
        </Stack>
        <Stack
          direction="row"
          spacing={0}
          sx={{
            justifyContent: "left",
            alignItems: "left",
            width: "100%",
            paddingBlock: "4px",
          }}
        >
          <Grid item xs={12} sm={10} md={3}>
            <Typography variant="h6" align="left">
              End Date :
            </Typography>
          </Grid>
          <Grid item xs={12} sm={10} md={6}>
            <FormControl
              variant="standard"
              sx={{ width: "100%", justifyContent: "center" }}
            >
              <TextField
                id="standard-position"
                type="date"
                variant="standard"
                value={leaveForms.end_date}
                onChange={(e) => handleTextChange("end_date", e.target.value)}
                inputProps={{
                  min: new Date(leaveForms.start_date || new Date())
                    .toISOString()
                    .split("T")[0], // Set minimum date to today's date
                }}
                sx={{
                  "& .MuiInput-root": {
                    borderBottom: "1px solid", // Fixed bold underline
                  },
                }}
                error={error && !leaveForms.end_date}
                helperText={
                  error && !leaveForms.end_date ? "End date is required" : ""
                }
              />
            </FormControl>
          </Grid>
        </Stack>
        <Stack
          direction="row"
          spacing={0}
          sx={{
            justifyContent: "left",
            alignItems: "left",
            width: "100%",
            paddingBlock: "4px",
          }}
        >
          <Grid item xs={12} sm={10} md={3}>
            <Typography variant="h6" align="left">
              Leave duration :
            </Typography>
          </Grid>
          <Grid item xs={12} sm={10} md={6}>
            <FormControl
              variant="standard"
              sx={{ width: "100%", justifyContent: "center" }}
            >
              <Typography variant="h6" align="left">
                {countLeaveDuration}
              </Typography>
              <Typography sx={{ color: "red" }}>
                Note: The duration is calculated based on the start and end date
              </Typography>
            </FormControl>
          </Grid>
        </Stack>
        <Stack
          direction="row"
          spacing={0}
          sx={{
            justifyContent: "left",
            alignItems: "left",
            width: "100%",
            paddingBlock: "4px",
          }}
        >
          <Grid item xs={12} sm={10} md={3}>
            <Typography variant="h6" align="left">
              Leave Reason :
            </Typography>
          </Grid>
          <Grid item xs={12} sm={10} md={6}>
            <FormControl
              variant="standard"
              sx={{ width: "100%", justifyContent: "center" }}
            >
              <TextField
                id="standard-position"
                type="text"
                multiline
                variant="standard"
                value={leaveForms.leave_reason}
                onChange={(e) =>
                  handleTextChange("leave_reason", e.target.value)
                }
                sx={{
                  "& .MuiInput-root": {
                    borderBottom: "1px solid", // Fixed bold underline
                  },
                }}
                error={error && !leaveForms.leave_reason}
                helperText={
                  error && !leaveForms.leave_reason
                    ? "Leave reason is required"
                    : ""
                }
              />
            </FormControl>
          </Grid>
        </Stack>
        <Stack
          direction="row"
          spacing={0}
          sx={{
            justifyContent: "left",
            alignItems: "left",
            width: "100%",
            paddingTop: "10px",
          }}
        >
          <Grid item xs={12} sm={12} md={12} sx={{ textAlign: "center" }}>
            <Button variant="contained" color="primary" onClick={handleSave}>
              Request Leave
            </Button>
          </Grid>
        </Stack>
      </Stack>
    </Grid>
  );
}

export default LeaveFormData;
