import { Paper, Stack, Typography } from "@mui/material";
import React, { useEffect } from "react";
import ResponsiveList from "../../../components/list/ResponsiveList";
import { useLeaveSummaryWidgetQuery } from "../../../app/api/reports/dashboardApiSlice";

function LeaveSummaryWidget() {
  const [categories, setCategories] = React.useState([
    { title: "Sick", value: 0 },
    { title: "Maternity", value: 0 },
    { title: "Vacation", value: 0 },
    { title: "Special", value: 0 },
  ]);

  const { data, isLoading } = useLeaveSummaryWidgetQuery(
    {},
    {
      refetchOnMountOrArgChange: true,
    }
  );

  useEffect(() => {
    if (data) {
      setCategories([
        { title: "Sick", value: data.sick_leave },
        { title: "Maternity", value: data.maternity_leave },
        { title: "Vacation", value: data.vacation_leave },
        { title: "Special", value: data.paternity_leave },
      ]);
    }
  }, [data]);
  return (
    <Paper elevation={3} sx={{ p: 2, height: "100%" }}>
      <Stack spacing={2}>
        <Typography
          variant="h6"
          sx={{
            fontWeight: 700,
            textAlign: "center",
            backgroundColor: "#E1F7F5",
            color: "black",
          }}
        >
          Leave Summary
        </Typography>
        <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
          <ResponsiveList categories={categories} />
        </Stack>
      </Stack>
    </Paper>
  );
}

export default LeaveSummaryWidget;
